import './index.module.scss';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import ContactForm from '../../../../components/contact-form/contact-form';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import ContentInner from '../../../../components/content-inner/content-inner';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        caption={
          <h2 className="x-large-heading color-white">Estate Planning.</h2>
        }
      />
      <ContentInner
        heading={
          'Our three step process to estate planning through our trusted partner includes the following.'
        }
        content={
          <p>
            <strong>Make a list</strong>
            <br />
            No matter how modest or great one’s wealth is, it all starts with a
            proper accounting of exactly what you have. List the value of your
            home, investments, insurance, statements from bank accounts, artwork
            and collectibles…anything of value. We can help you with appraisals
            and resources to make this process as efficient as possible. The
            goal is to have a consolidated, detailed list that can be accessed
            by loved ones and trusted individuals.
            <br />
            <br />
            <strong>Draft your estate plan</strong>
            <br />
            Meet with an estate attorney and draw up a plan. This not only
            includes who gets what assets and who distributes them, but other
            issues such as long-term care for loved ones that are in your life.
            How much is needed for any children and their education? Who manages
            your financial affairs if you are incapacitated? These are all items
            to be considered and addressed.
            <br /> <br />
            <strong>Fund and Update</strong>
            <br />
            Putting off funding any estate can naturally lead to poor outcomes,
            so we encourage individuals to do so as soon as an estate is
            established. An annual review of everything should also be done, as
            assets may have changed and you may want to add or change
            beneficiaries. Reviewing an estate plan and the terms of your will
            annually will ensure that your legacy wishes are met and there are
            no unintended surprises for heirs or business partners.
            <br /> <br />
            <strong>We are here to help</strong>
            <br />
            We have managed estate accounts for more than twenty years and can
            be of help to you in ensuring that your assets both grow according
            to plan, but also go to the right places per your instructions.
          </p>
        }
      />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
